import {ExchangeModel, RegionModel} from "./exchange.model"
import {mapResponseDataToCurrencyPairModels} from "../currencypair/currency-pair.mapper"

export const mapResponseDataToExchangeModels = (data: any): ExchangeModel[] => {
    let exchanges = []
    if (data && data.length > 0) {
        exchanges = data.map((d: any) => mapResponseDataToExchangeModel(d))
    }
    return exchanges
}

export const mapResponseDataToExchangeModel = (data: any): ExchangeModel => {
    return data && {
        identifier: data.identifier,
        active: data.active,
        type: data.type,
        region: mapReponseDataToRegionModel(data.region),
        currencyPairs: mapResponseDataToCurrencyPairModels(data.currencyPairs)
    }
}

export const mapReponseDataToRegionModel = (data: any): RegionModel => {
    return data && {
        name: data.name,
        code: data.code
    }
}