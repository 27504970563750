import React, {useEffect, useState} from "react"
import {useAuth0} from "@auth0/auth0-react"
import {ExchangeModel} from "../../services/exchange/exchange.model"
import {getAllExchanges} from "../../services/exchange/exchange.service"
import {LoadingSpinner} from "../loading-spinner/loading-spinner.component"
import Alert from "../alert/alert.component"
import {AlertType} from "../alert/alert.enum"
import Heading from "../heading/heading.component"
import {CurrencyPairModel} from "../../services/currencypair/currency-pair.model"
import {useNavigate} from "react-router-dom"
import {EXCHANGE} from "../../routes"
import {GET_TOKEN_OPTIONS} from "../../services/auth0.util"

const Exchanges = () => {
    const { getAccessTokenSilently } = useAuth0()
    const navigate = useNavigate()
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [exchanges, setExchanges] = useState<ExchangeModel[]>([])

    useEffect(() => {
        const requestAllExchanges = async () => {
            try {
                setExchanges(await getAllExchanges(await getAccessTokenSilently(GET_TOKEN_OPTIONS)))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        requestAllExchanges()
    }, [])

    return (
        <>
            <Heading
                left={<h2>Exchanges &amp; Currencies</h2>}
            />
            {state === "LOADING" && <LoadingSpinner/>}
            {state === "LOADED" && (
                <table className="linked">
                    <thead>
                        <tr>
                            <th>Identifier</th>
                            <th>Active?</th>
                            <th>Type</th>
                            <th>Region</th>
                            <th># Currency Pairs to Watch</th>
                            <th># Active Currency Pairs to Watch</th>
                        </tr>
                    </thead>
                    <tbody>
                        {exchanges.map((exchange, index) => (
                            <tr
                                key={index}
                                onClick={() => navigate(EXCHANGE(exchange.identifier))}
                            >
                                <td>{exchange.identifier}</td>
                                <td>{exchange.active ? "yes" : "no"}</td>
                                <td>{exchange.type.toString().toLowerCase()}</td>
                                <td>{exchange.region.name} ({exchange.region.code})</td>
                                <td>{exchange.currencyPairs.length}</td>
                                <td>{evaluateNumberOfActiveCurrencyPairsToWatch(exchange.currencyPairs)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load exchanges."
                />
            )}
        </>
    )
}

export default Exchanges

const evaluateNumberOfActiveCurrencyPairsToWatch = (currencyPairs: CurrencyPairModel[]): number => {
    return currencyPairs
        .filter(p => p.active)
        .length
}