import React, {FunctionComponent} from "react"
import AButton from "../button/button.component"
import {useNavigate} from "react-router-dom"
import {OPPORTUNITIES_EXCHANGE, OPPORTUNITIES_INTER_EXCHANGE} from "../../routes"
import ExchangeSelector from "../exchange/selector.component"
import "./opportunity.css"

type OpportunitiesExchangeSelectorProps = {
    exchangeIdentifier?: string
}

const OpportunitiesExchangeSelector: FunctionComponent<OpportunitiesExchangeSelectorProps> = ({ exchangeIdentifier }) => {
    const navigate = useNavigate()

    const onExchangeSelect = (identifier: string) => {
        navigate(OPPORTUNITIES_EXCHANGE(identifier))
    }

    return (
        <div className="inter-exchange-selector">
            <AButton
                text="Inter-Exchange"
                onClick={() => navigate(OPPORTUNITIES_INTER_EXCHANGE)}
                style={{ marginRight: "8px" }}
                theme="light"
            />
            <ExchangeSelector
                exchangeIdentifier={exchangeIdentifier}
                onExchangeSelect={onExchangeSelect}
            />
        </div>
    )
}

export default OpportunitiesExchangeSelector