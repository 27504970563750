import React, {FunctionComponent, useState} from "react"
import {ExchangeModel} from "../../services/exchange/exchange.model"
import {LoadingSpinner} from "../loading-spinner/loading-spinner.component"
import Alert from "../alert/alert.component"
import {AlertType} from "../alert/alert.enum"
import {CurrencyPairModel} from "../../services/currencypair/currency-pair.model"
import {activateCurrencyPair, deactivateCurrencyPair} from "../../services/currencypair/currency-pair.service"
import {useAuth0} from "@auth0/auth0-react"
import {GET_TOKEN_OPTIONS} from "../../services/auth0.util"

const CurrencyPairToggleActive: FunctionComponent<{ pair: CurrencyPairModel }> = ({ pair }) => {
    const { getAccessTokenSilently } = useAuth0()
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADED")

    const toggleActive = async () => {
        setState("LOADING")
        try {
            if (pair.active) {
                await deactivateCurrencyPair(pair.id, await getAccessTokenSilently(GET_TOKEN_OPTIONS))
            } else {
                await activateCurrencyPair(pair.id, await getAccessTokenSilently(GET_TOKEN_OPTIONS))
            }
            window.location.reload()
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingSpinner/>}
            {state === "LOADED" && (
                <button onClick={() => toggleActive()}>
                    {pair.active ? "deactivate" : "activate"}
                </button>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to activate / deactivate currency pair."
                />
            )}
        </>
    )
}

export default CurrencyPairToggleActive

const getAndSortCurrencyPairs = (exchange: ExchangeModel) => {
    return exchange
        .currencyPairs
        .sort((a, b) => {
            return Number(b.active) - Number(a.active)
                || a.id - b.id
        })
}