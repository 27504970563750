import React from "react"
import ReactDOM from "react-dom"
import {Auth0Provider} from "@auth0/auth0-react"
import {BrowserRouter} from "react-router-dom"
import App from "./App"
import "./index.css"

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Auth0Provider
                domain="dev-krq3tvg8.us.auth0.com"
                clientId="KIb13yvuXeR90ikVDt67g1KZ3H2FOd7E"
                redirectUri={window.location.origin}
                audience="https://arbitrage.beele.io"
                scope="manage:all"
            >
                <App/>
            </Auth0Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
)