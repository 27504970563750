import {OpportunePairModel, OpportunityModel, Strategy} from "./opportunity.model"
import {mapResponseDataToExchangeModel} from "../exchange/exchange.mapper"
import {mapResponseDataToCurrencyPairModel} from "../currencypair/currency-pair.mapper"
import {DateTime} from "luxon"

export const mapResponseDataToOpportunityModels = (data: any): OpportunityModel[] => {
    let opportunities = []
    if (data && data.length > 0) {
        opportunities = data.map((d: any) => mapResponseDataToOpportunityModel(d))
    }
    return opportunities
}

export const mapResponseDataToOpportunityModel = (data: any): OpportunityModel => {
    return data && {
        id: data.id,
        pair1: mapResponseDataToOpportunePairModel(data.pair1),
        pair2: mapResponseDataToOpportunePairModel(data.pair2),
        pair3: mapResponseDataToOpportunePairModel(data.pair3),
        crossRate: data.crossRate,
        evaluated: data.evaluated && DateTime.fromISO(data.evaluated)
    }
}

const mapResponseDataToOpportunePairModel = (data: any): OpportunePairModel => {
    return data && {
        currencyPair: mapResponseDataToCurrencyPairModel(data.currencyPair),
        price: data.price,
        quantity: data.quantity,
        time: data.time && DateTime.fromISO(data.time),
        received: data.received && DateTime.fromISO(data.received),
        strategy: mapResponseDataToStrategy(data.strategy)
    }
}

const mapResponseDataToStrategy = (data: any): Strategy => {
    return data && (data === "BUY" ? Strategy.BUY : Strategy.SELL)
}