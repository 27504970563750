import {ExchangeModel} from "./exchange.model"
import {getRequest} from "../generic.service"
import {mapResponseDataToExchangeModel, mapResponseDataToExchangeModels} from "./exchange.mapper"
import {AccessToken} from "../auth0.util"

const BASE_URL = "/admin/exchange/"

export const getAllExchanges = async (accessToken: AccessToken): Promise<ExchangeModel[]> => {
    const res = await getRequest(BASE_URL, accessToken)
    const data = await res.data
    if (!res.success) throw new Error("Failed to load exchanges")
    return mapResponseDataToExchangeModels(data)
}

export const getExchange = async (identifier: string, accessToken: AccessToken): Promise<ExchangeModel> => {
    const res = await getRequest(BASE_URL + identifier, accessToken)
    const data = await res.data
    if (!res.success) throw new Error("Failed to load exchange")
    return mapResponseDataToExchangeModel(data)
}

export const syncCurrencyPairs = async (identifier: string, accessToken: AccessToken): Promise<ExchangeModel> => {
    const res = await getRequest(BASE_URL + identifier + "/sync", accessToken)
    const data = await res.data
    if (!res.success) throw new Error("Failed to sync exchange")
    return mapResponseDataToExchangeModel(data)
}