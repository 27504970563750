import {OrderBookModel, OrderModel} from "./debug.model"
import {DateTime} from "luxon"

export const mapResponseDataToOrderBookModels = (data: any): OrderBookModel[] => {
    let exchanges = []
    if (data && data.length > 0) {
        exchanges = data.map((d: any) => mapResponseDataToOrderBookModel(d))
    }
    return exchanges
}

export const mapResponseDataToOrderBookModel = (data: any): OrderBookModel => {
    return data && {
        currencyPair: data.currencyPair,
        asks: mapResponseDataToOrderModels(data.asks),
        bids: mapResponseDataToOrderModels(data.bids)
    }
}

export const mapResponseDataToOrderModels = (data: any): OrderModel[] => {
    let exchanges = []
    if (data && data.length > 0) {
        exchanges = data.map((d: any) => mapResponseDataToOrderModel(d))
    }
    return exchanges
}

export const mapResponseDataToOrderModel = (data: any): OrderBookModel => {
    return data && {
        price: data.price,
        quantity: data.quantity,
        time: data.time && DateTime.fromISO(data.time),
        received: data.received && DateTime.fromISO(data.received)
    }
}