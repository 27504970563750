import {Path3Model} from "./debug.model"

export const mapResponseDataToPath3Models = (data: any): Path3Model[] => {
    let exchanges = []
    if (data && data.length > 0) {
        exchanges = data.map((d: any) => mapResponseDataToPath3Model(d))
    }
    return exchanges
}

export const mapResponseDataToPath3Model = (data: any): Path3Model => {
    return data && {
        pair1: data.pair1,
        pair2: data.pair2,
        pair3: data.pair3
    }
}