import {CurrencyPairModel} from "../currencypair/currency-pair.model"
import {DateTime} from "luxon"

export type OpportunityModel = {
    id: number
    pair1: OpportunePairModel
    pair2: OpportunePairModel
    pair3: OpportunePairModel
    crossRate: number
    evaluated: DateTime
}

export type OpportunePairModel = {
    currencyPair: CurrencyPairModel
    price: number
    quantity: number
    time: DateTime
    received: DateTime
    strategy: Strategy
}

export enum Strategy {
    BUY = "BUY",
    SELL = "SELL"
}