import React, {FunctionComponent} from "react"
import {OpportunePairModel} from "../../services/opportunity/opportunity.model"
import {DateTime} from "luxon"
import "./opportunity.css"

type OpportunePairProps = {
    pair: OpportunePairModel
}

const OpportunePair: FunctionComponent<OpportunePairProps> = ({ pair }) => {
    return (
        <>
            <div className="opportunity-rate-row">
                <strong>
                    {pair.currencyPair.baseCurrency} / {pair.currencyPair.quoteCurrency}
                </strong>
            </div>
            <div className="opportunity-rate-row">
                Price: {pair.price}
            </div>
            <div className="opportunity-rate-row">
                Quantity: {pair.quantity}
            </div>
            <div className="opportunity-rate-row">
                Strategy: {pair.strategy}
            </div>
            <div className="opportunity-rate-row">
                Sent: {pair.time.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
            </div>
            <div>
                Received: {pair.received.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
            </div>
        </>
    )
}

export default OpportunePair