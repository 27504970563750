import React, {FunctionComponent, ReactNode} from "react"
import Header from "../header/header.component"
import "./layout.css"

const Layout: FunctionComponent<{ children: ReactNode }> = ({children}) => {
    return (
        <>
            <Header/>
            <main className="container">
                {children}
            </main>
        </>
    )
}

export default Layout