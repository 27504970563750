import React from "react"
import {Route, Routes} from "react-router-dom"
import {useAuth0} from "@auth0/auth0-react"
import Layout from "./components/layout/layout.component"
import TBD from "./components/tbd.component"
import {DEBUG, DECISION_DATA, EXCHANGES, HOME, OPPORTUNITIES, OPPORTUNITIES_INTER_EXCHANGE, RESTART, TRADES} from "./routes"
import Exchange from "./components/exchange/single.component"
import Exchanges from "./components/exchange/overview.component"
import OpportunitiesInterExchange from "./components/opportunity/overview-interexchange.component"
import Restart from "./components/restart/restart.component"
import OpportunitiesExchange from "./components/opportunity/overview-exchange.component"
import Debug from "./components/debug/debug.component"
import {LoadingSpinner} from "./components/loading-spinner/loading-spinner.component";

const App = () => {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

    if (isAuthenticated) {
        return (
            <div>
                <Layout>
                    <Routes>
                        <Route path={HOME} element={<Exchanges />} />

                        <Route path={DECISION_DATA} element={<TBD />} />

                        <Route path={EXCHANGES} element={<Exchanges />} />
                        <Route path={EXCHANGES + `:identifier`} element={<Exchange />} />

                        <Route path={OPPORTUNITIES_INTER_EXCHANGE} element={<OpportunitiesInterExchange />} />
                        <Route path={OPPORTUNITIES + `exchange/:exchangeIdentifier`} element={<OpportunitiesExchange />} />

                        <Route path={TRADES} element={<TBD />} />

                        <Route path={DEBUG} element={<Debug />} />

                        <Route path={RESTART} element={<Restart />} />
                    </Routes>
                </Layout>
            </div>
        )
    } else {
        if (!isLoading) {
            loginWithRedirect().then(() => {})
        }
        return (
            <LoadingSpinner/>
        )
    }
}

export default App
