import React, {FunctionComponent} from "react"
import {Path3Model} from "../../services/debug/debug.model"

type PathsDebugProps = {
    paths: Path3Model[] | undefined
}

const PathsDebug: FunctionComponent<PathsDebugProps> = ({ paths }) => {
    return (
        <>
            <h3>Paths</h3>
            {paths && paths.length > 0 ? (
                <ul>
                    {paths.map((path: Path3Model, index) => (
                        <li key={index} style={{ marginBottom: "8px" }}>
                            {path.pair1} &minus; {path.pair2} &minus; {path.pair3}
                        </li>
                    ))}
                </ul>
            ) : (
                <>No paths available.</>
            )}
        </>
    )
}

export default PathsDebug