import React, {FunctionComponent} from "react"
import {OrderBookModel, OrderModel} from "../../services/debug/debug.model"
import OrderDebug from "./order.component"

type OrderBookDebugProps = {
    orderBooks?: OrderBookModel[]
}

const OrderBookDebug: FunctionComponent<OrderBookDebugProps> = ({ orderBooks }) => {
    return (
        <>
            <h3>Order Book</h3>
            {orderBooks && orderBooks.length > 0 ? (
                <>
                    {orderBooks.map((orderBook: OrderBookModel, index) => (
                        <div key={index} style={{ marginTop: "-15px", padding: "25px 0", borderBottom: "3px solid #dee2e6" }}>
                            <strong style={{ textDecoration: "underline" }}>{orderBook.currencyPair}</strong>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>
                                            Asks{" "}
                                            <small style={{ fontWeight: "normal" }}>
                                                ({orderBook.asks.length} asks)
                                            </small>
                                        </th>
                                        <th>
                                            Bids{" "}
                                            <small style={{ fontWeight: "normal" }}>
                                                ({orderBook.bids.length} bids)
                                            </small>
                                        </th>
                                    </tr>
                                    {transformToTableEntries(orderBook).map((e: TransformedOrderBook, index) => (
                                        <tr key={index}>
                                            <td>
                                                <OrderDebug order={e.ask}/>
                                            </td>
                                            <td>
                                                <OrderDebug order={e.bid}/>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td>...</td>
                                        <td>...</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ))}
                </>
            ) : (
                <>No entries available.</>
            )}
        </>
    )
}

export default OrderBookDebug

type TransformedOrderBook = {
    ask: OrderModel,
    bid: OrderModel
}

const transformToTableEntries = (orderBook: OrderBookModel): TransformedOrderBook[] => {
    const result = []
    for (let i = 0; i < 5; i++) {
        if (!orderBook.asks[i] && !orderBook.bids[i]) {
            break;
        }
        result.push({
            ask: orderBook.asks[i],
            bid: orderBook.bids[i]
        })
    }
    return result
}