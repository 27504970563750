import React, {CSSProperties, FunctionComponent} from "react"

type AButtonProps = {
    text: string
    onClick: Function
    style?: CSSProperties
    theme?: "dark" | "light"
}

const AButton: FunctionComponent<AButtonProps> = ({ text, onClick, style, theme = "dark" }) => {
    return (
        <button
            className={`button ${theme}`}
            onClick={() => onClick()}
            style={style}
        >
            {text}
        </button>
    )
}

export default AButton