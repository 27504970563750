import React, {FunctionComponent} from "react"
import {AlertType} from "./alert.enum"
import "./alert.css"

const Alert: FunctionComponent<{ type: AlertType, text: string, style?: any }> = ({
    type,
    text,
    style
}) => {
    return (
        <div className={getAlertClass(type)} style={style}>
            {text}
        </div>
    )
}

export default Alert

const getAlertClass = (alertType: AlertType) => {
    switch (alertType) {
        case AlertType.SUCCESS:
            return "alert alertSuccess"
        case AlertType.INFO:
            return "alert alertInfo"
        case AlertType.WARNING:
            return "alert alertWarning"
        case AlertType.ERROR:
            return "alert alertError"
    }
}