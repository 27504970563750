import React, {FunctionComponent, useEffect, useState} from "react"
import {useAuth0} from "@auth0/auth0-react"
import {ExchangeModel} from "../../services/exchange/exchange.model"
import {getAllExchanges} from "../../services/exchange/exchange.service"
import {GET_TOKEN_OPTIONS} from "../../services/auth0.util"

type ExchangeSelectorProps = {
    exchangeIdentifier?: string
    onExchangeSelect: (identifier: string) => void
}

const ExchangeSelector: FunctionComponent<ExchangeSelectorProps> = ({ exchangeIdentifier, onExchangeSelect }) => {
    const { getAccessTokenSilently } = useAuth0()
    const [exchanges, setExchanges] = useState<ExchangeModel[]>([])

    useEffect(() => {
        const requestAllExchanges = async () => {
            try {
                setExchanges(await getAllExchanges(await getAccessTokenSilently(GET_TOKEN_OPTIONS)))
            } catch (err) {
                console.error(err)
            }
        }
        requestAllExchanges()
    }, [])

    return (
        <select
            value={exchangeIdentifier}
            onChange={(e) => onExchangeSelect(e.target.value)}
            style={{ fontWeight: "bold", paddingLeft: "5px", paddingRight: "5px", minHeight: "38px" }}
        >
            <option value="none">
                ----
            </option>
            {exchanges.map((exchange, index) => (
                <option
                    key={index}
                    value={exchange.identifier}
                >
                    {exchange.identifier}
                </option>
            ))}
        </select>
    )
}

export default ExchangeSelector


export const isExchangeIdentifier = (exchangeIdentifier: string|undefined): boolean => {
    return exchangeIdentifier !== undefined
        && exchangeIdentifier !== "none"
}