import React, {useState} from "react"
import {useAuth0} from "@auth0/auth0-react"
import Heading from "../heading/heading.component"
import AButton from "../button/button.component"
import {triggerRestart} from "../../services/restart/restart.service"
import {LoadingSpinner} from "../loading-spinner/loading-spinner.component"
import Alert from "../alert/alert.component"
import {AlertType} from "../alert/alert.enum"
import {GET_TOKEN_OPTIONS} from "../../services/auth0.util"

const Restart = () => {
    const { getAccessTokenSilently } = useAuth0()
    const [state, setState] = useState<"NONE" | "LOADING" | "TRIGGERED" | "ERROR">("NONE")

    const restart = async () => {
        setState("LOADING")
        try {
            await triggerRestart(await getAccessTokenSilently(GET_TOKEN_OPTIONS))
            setState("TRIGGERED")
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            <Heading
                left={<h2>Restart</h2>}
            />
            {state === "NONE" && (
                <AButton
                    text="Trigger restart"
                    onClick={() => restart()}
                />
            )}
            {state === "LOADING" && <LoadingSpinner/>}
            {state === "TRIGGERED" && (
                <Alert
                    type={AlertType.INFO}
                    text="Successfully triggered restart."
                />
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Something went wrong."
                />
            )}
        </>
    )
}

export default Restart