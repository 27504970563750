import {AccessToken} from "./auth0.util"

const BACKEND_URL_LOCAL = "http://localhost:8080"
const BACKEND_URL_PRODUCTION = "https://arbitrage-backend.beele.io:8443"

export type ResponseResult = {
    success: boolean,
    data: any
}

export const getRequest = async (path: string, accessToken: AccessToken): Promise<ResponseResult> => {
    let res
    try {
        const response = await fetch(buildUrl(path), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
            mode: "cors"
        })
        res = {
            success: response.ok,
            data: getDataFromResponse(response)
        }
    } catch (err) {
        console.error(err)
        res = { success: false, data: null }
    }
    return res
}

export const postRequest = async (path: string, data: any, accessToken: AccessToken): Promise<ResponseResult> => {
    return modifyingRequest("POST", path, data, accessToken)
}

export const putRequest = async (path: string, data: any, accessToken: AccessToken): Promise<ResponseResult> => {
    return modifyingRequest("PUT", path, data, accessToken)
}

const modifyingRequest = async (
    method: "POST" | "PUT",
    path: string,
    data: any,
    accessToken: AccessToken
): Promise<ResponseResult> => {
    let res
    try {
        const response = await fetch(buildUrl(path), {
            method: method,
            body: JSON.stringify(data),
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-type": "application/json; charset=UTF-8"
            },
            mode: "cors"
        })
        res = {
            success: response.ok,
            data: getDataFromResponse(response)
        }
    } catch (err) {
        console.error(err)
        res = { success: false, data: null }
    }
    return res
}

const buildUrl = (path: string) => {
    return getBaseUrl() + path
}

const getBaseUrl = () => {
    console.log("NODE_ENV")
    console.log(process.env.NODE_ENV)
    return process.env.NODE_ENV === "production" ? BACKEND_URL_PRODUCTION : BACKEND_URL_LOCAL
}

const getDataFromResponse = async (response: any): Promise<undefined | any> => {
    let res
    try {
        res = response != null ? await response.json() : undefined
    } catch (err) {
        console.error(err)
        res = undefined
    }
    return res
}