import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useAuth0} from "@auth0/auth0-react"
import {OpportunityModel} from "../../services/opportunity/opportunity.model"
import OpportunitiesExchangeSelector from "./selector.component"
import Heading from "../heading/heading.component"
import {getAllOpportunitiesByExchangeAndBetween} from "../../services/opportunity/opportunity.service"
import {isExchangeIdentifier} from "../exchange/selector.component"
import {LoadingSpinner} from "../loading-spinner/loading-spinner.component"
import Alert from "../alert/alert.component"
import {AlertType} from "../alert/alert.enum"
import TimeSpan from "./time-span.component"
import {DateTime} from "luxon"
import "./opportunity.css"
import OpportunePair from "./pair.component"
import {GET_TOKEN_OPTIONS} from "../../services/auth0.util"

const OpportunitiesExchange = () => {
    const { getAccessTokenSilently } = useAuth0()
    const { exchangeIdentifier } = useParams()
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [start, setStart] = useState<DateTime>(DateTime.now().minus({ days: 2 }))
    const [end, setEnd] = useState<DateTime>(DateTime.now())
    const [opportunities, setOpportunities] = useState<OpportunityModel[]>([])

    useEffect(() => {
        loadOpportunities()
    }, [exchangeIdentifier, start, end])

    const loadOpportunities = async () => {
        if (isExchangeIdentifier(exchangeIdentifier)) {
            setState("LOADING")
            try {
                setOpportunities(await getAllOpportunitiesByExchangeAndBetween(
                    exchangeIdentifier!!,
                    start,
                    end,
                    await getAccessTokenSilently(GET_TOKEN_OPTIONS)
                ))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
    }

    return (
        <>
            <Heading
                left={<h2 style={{ margin: 0 }}>Opportunities ({exchangeIdentifier})</h2>}
                right={<OpportunitiesExchangeSelector exchangeIdentifier={exchangeIdentifier}/>}
            />
            {isExchangeIdentifier(exchangeIdentifier) ? (
                <>
                    <div className="opportunity-overview-head">
                        <div className="opportunity-overview-head-opportunities">
                            {opportunities && opportunities.length > 0 && (
                                <div>
                                    ({opportunities.length} opportunities)
                                </div>
                            )}
                        </div>
                        <TimeSpan
                            start={start}
                            updateStart={setStart}
                            end={end}
                            updateEnd={setEnd}
                            onRefresh={loadOpportunities}
                        />
                    </div>
                    {state === "LOADED" && (
                        <>
                            {(opportunities !== undefined && opportunities.length > 0) ? (
                                <table className="opportunities-table">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Pair 1</th>
                                        <th>Pair 2</th>
                                        <th>Pair 3</th>
                                        <th>Cross-Rate</th>
                                        <th>Evaluated</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {opportunities.map((opportunity, index) => (
                                        <tr key={index}>
                                            <td>{opportunity.id}</td>
                                            <td>
                                                <OpportunePair pair={opportunity.pair1}/>
                                            </td>
                                            <td>
                                                <OpportunePair pair={opportunity.pair2}/>
                                            </td>
                                            <td>
                                                <OpportunePair pair={opportunity.pair3}/>
                                            </td>
                                            <td>{opportunity.crossRate.toFixed(4)}</td>
                                            <td>
                                                {opportunity.evaluated.toLocaleString(DateTime.DATE_SHORT)}<br/>
                                                {opportunity.evaluated.toLocaleString(DateTime.TIME_WITH_SECONDS)}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            ) : (
                                <>No opportunities.</>
                            )}
                        </>
                    )}
                    {state === "LOADING" && <LoadingSpinner/>}
                    {state === "ERROR" && (
                    <Alert
                        type={AlertType.ERROR}
                        text="Something went wrong."
                    />
                )}
                </>
            ) : (
                <>Select an exchange.</>
            )}
        </>
    )
}

export default OpportunitiesExchange