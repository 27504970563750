import React from "react"
import OpportunitiesExchangeSelector from "./selector.component"
import Heading from "../heading/heading.component"

const OpportunitiesInterExchange = () => {
    /*
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [opportunities, setOpportunities] = useState<OpportunityModel[]>([])
     */

    return (
        <>
            <Heading
                left={<h2 style={{ margin: 0 }}>Opportunities (inter-exchange)</h2>}
                right={<OpportunitiesExchangeSelector/>}
            />

            tbd
        </>
    )

    /*
    useEffect(() => {
        const date = new Date(Date.UTC(2022, 1))
        if (exchange === "all") {
            getAllOpportunitiesByInterExchangeAndAfter(date)
                .then(os => {
                    setOpportunities(os)
                    setState("LOADED")
                })
                .catch(() => setState("ERROR"))
        } else {
            getAllOpportunitiesByExchangeAndAfter(exchange, date)
                .then(os => {
                    setOpportunities(os)
                    setState("LOADED")
                })
                .catch(() => setState("ERROR"))
        }
    }, [exchange])

    return (
        <>
            <Heading
                left={<h2>Opportunities</h2>}
                right={
                    <>
                        <ExchangeSelector
                            value={exchange}
                            onClick={setExchange}
                        />
                    </>
                }
            />
            {state === "LOADING" && <LoadingSpinner/>}
            {state === "LOADED" && (
                <table className="linked opportunity-table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Evaluated</th>
                        <th>Exchange</th>
                        <th>Cross-Rate</th>
                        <th>Max-Volume</th>
                        <th>Rate1</th>
                        <th>Rate2</th>
                        <th>Rate3</th>
                    </tr>
                    </thead>
                    <tbody>
                    {opportunities.map((opportunity, index) => (
                        <tr key={index}>
                            <td>{opportunity.id}</td>
                            <td>{opportunity.evaluated.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</td>
                            <td>
                                {opportunity.interExchange && "Inter-Exchange"}
                                {!opportunity.interExchange && opportunity.exchange.identifier}
                            </td>
                            <td>{opportunity.crossRate}</td>
                            <td>{opportunity.maxVolume}</td>
                            <td>
                                <OpportunityRate rate={opportunity.rate1}/>
                            </td>
                            <td>
                                <OpportunityRate rate={opportunity.rate2}/>
                            </td>
                            <td>
                                <OpportunityRate rate={opportunity.rate3}/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load opportunities."
                />
            )}
        </>
    )
     */
}

export default OpportunitiesInterExchange