import {postRequest} from "../generic.service"
import {OpportunityModel} from "./opportunity.model"
import {mapResponseDataToOpportunityModels} from "./opportunity.mapper"
import {DateTime} from "luxon"
import {AccessToken} from "../auth0.util"

const BASE_URL = "/admin/opportunity"

export const getAllOpportunitiesByExchangeAndBetween = async (
    exchangeIdentifier: string,
    start: DateTime|undefined,
    end: DateTime|undefined,
    accessToken: AccessToken
): Promise<OpportunityModel[]> => {
    const res = await postRequest(
        `${BASE_URL}/exchange/${exchangeIdentifier}`,
        constructPayload(start, end),
        accessToken
    )
    const data = await res.data
    if (!res.success) throw new Error("Failed to load opportunities (exchange)")
    return mapResponseDataToOpportunityModels(data)
}

export const getAllOpportunitiesByInterExchangeAndBetween = async (
    start: DateTime|undefined,
    end: DateTime|undefined,
    accessToken: AccessToken
): Promise<OpportunityModel[]> => {
    const res = await postRequest(
        `${BASE_URL}/inter-exchange`,
        constructPayload(start, end),
        accessToken
    )
    const data = await res.data
    if (!res.success) throw new Error("Failed to load opportunities (inter-exchange)")
    return mapResponseDataToOpportunityModels(data)
}

const constructPayload = (start: DateTime|undefined, end: DateTime|undefined) => {
    return {
        start,
        end
    }
}