import {getRequest} from "../generic.service"
import {mapResponseDataToDebugModel} from "./debug.mapper"
import {DebugModel} from "./debug.model"
import {AccessToken} from "../auth0.util"

const BASE_URL = "/admin/debug/"

export const getDebugState = async (exchangeIdentifier: string, accessToken: AccessToken): Promise<DebugModel> => {
    const res = await getRequest(BASE_URL + exchangeIdentifier, accessToken)
    const data = await res.data
    if (!res.success) throw new Error("Failed to load exchange")
    return mapResponseDataToDebugModel(data)
}