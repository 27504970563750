import {getRequest} from "../generic.service"
import {AccessToken} from "../auth0.util"

const BASE_URL = "/admin/currencypair/"

export const activateCurrencyPair = async (id: number, accessToken: AccessToken): Promise<void> => {
    const res = await getRequest(BASE_URL + id + "/activate", accessToken)
    if (!res.success) throw new Error("Failed to activate currency pair")
}

export const deactivateCurrencyPair = async (id: number, accessToken: AccessToken): Promise<void> => {
    const res = await getRequest(BASE_URL + id + "/deactivate", accessToken)
    if (!res.success) throw new Error("Failed to activate currency pair")
}