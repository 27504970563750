import React, {FunctionComponent} from "react"
import {OrderModel} from "../../services/debug/debug.model"
import {DateTime} from "luxon"

type OrderDebugProps = {
    order: OrderModel | undefined
}

const OrderDebug: FunctionComponent<OrderDebugProps> = ({ order }) => {
    return order ? (
        <>
            {order.price} &times; {order.quantity}{" "}
            <small>({order.received.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)})</small>
        </>
    ) : (
        <></>
    )
}

export default OrderDebug