import React, {useEffect, useState} from "react"
import {useAuth0} from "@auth0/auth0-react"
import Heading from "../heading/heading.component"
import {LoadingSpinner} from "../loading-spinner/loading-spinner.component"
import Alert from "../alert/alert.component"
import {AlertType} from "../alert/alert.enum"
import ExchangeSelector, {isExchangeIdentifier} from "../exchange/selector.component"
import OrderBookDebug from "./order-book.component"
import PathsDebug from "./paths.component"
import {getDebugState} from "../../services/debug/debug.service"
import {DebugModel} from "../../services/debug/debug.model"
import AButton from "../button/button.component"
import "./debug.css"
import {GET_TOKEN_OPTIONS} from "../../services/auth0.util"

const Debug = () => {
    const { getAccessTokenSilently } = useAuth0()
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [debugState, setDebugState] = useState<DebugModel>()
    const [exchangeIdentifier, setExchangeIdentifier] = useState<string>()

    useEffect(() => {
        queryDebugState()
    }, [exchangeIdentifier])

    const queryDebugState = async () => {
        if (isExchangeIdentifier(exchangeIdentifier)) {
            setState("LOADING")
            try {
                setDebugState(await getDebugState(exchangeIdentifier!!, await getAccessTokenSilently(GET_TOKEN_OPTIONS)))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
    }

    const onExchangeSelect = (identifier: string) => {
        setExchangeIdentifier(identifier)
    }

    return (
        <>
            <Heading
                left={<h2>DEBUG</h2>}
                right={
                    <div className="debug-selector">
                        {isExchangeIdentifier(exchangeIdentifier) && (<AButton
                            text="Refresh"
                            onClick={() => queryDebugState()}
                            style={{ marginRight: "8px" }}
                            theme="light"
                        />)}
                        <ExchangeSelector
                            exchangeIdentifier={exchangeIdentifier}
                            onExchangeSelect={onExchangeSelect}
                        />
                    </div>
                }
            />
            {!isExchangeIdentifier(exchangeIdentifier) ? (
                <>Select an exchange.</>
            ) : (
                <>
                    {state === "LOADED" && (
                        <>
                            <PathsDebug paths={debugState?.paths}/>
                            <br/><br/>
                            <OrderBookDebug orderBooks={debugState?.orderBooks}/>
                        </>
                    )}
                    {state === "LOADING" && <LoadingSpinner/>}
                    {state === "ERROR" && (
                        <Alert
                            type={AlertType.ERROR}
                            text="Something went wrong."
                        />
                    )}
                </>
            )}
        </>
    )
}

export default Debug