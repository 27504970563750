import {useParams} from "react-router-dom"
import React, {useEffect, useState} from "react"
import {useAuth0} from "@auth0/auth0-react"
import {ExchangeModel} from "../../services/exchange/exchange.model"
import {getExchange, syncCurrencyPairs} from "../../services/exchange/exchange.service"
import Heading from "../heading/heading.component"
import {LoadingSpinner} from "../loading-spinner/loading-spinner.component"
import Alert from "../alert/alert.component"
import {AlertType} from "../alert/alert.enum"
import {DateTime} from "luxon"
import AButton from "../button/button.component"
import CurrencyPairToggleActive from "./toggle.component"
import {GET_TOKEN_OPTIONS} from "../../services/auth0.util"

const Exchange = () => {
    const { getAccessTokenSilently } = useAuth0()
    const { identifier } = useParams()
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [exchange, setExchange] = useState<ExchangeModel>()
    const [searchString1, setSearchString1] = useState<string>("")
    const [searchString2, setSearchString2] = useState<string>("")

    useEffect(() => {
        const requestExchange = async () => {
            try {
                setExchange(await getExchange(identifier!!, await getAccessTokenSilently(GET_TOKEN_OPTIONS)))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        requestExchange()
    }, [identifier])

    const syncPairs = async () => {
        setState("LOADING")
        try {
            setExchange(await syncCurrencyPairs(identifier!!, await getAccessTokenSilently(GET_TOKEN_OPTIONS)))
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    const search = (e: any) => {
        const splitted = e.target.value.split("/")
        setSearchString1(splitted[0] != null ? splitted[0] : "")
        setSearchString2(splitted[1] != null ? splitted[1] : "")
    }

    return (
        <>
            <Heading
                left={<h2>Exchange ({identifier})</h2>}
                right={
                    <>
                        <input
                            type="text"
                            placeholder="BTC/ETH"
                            style={{ paddingLeft: "10px", paddingRight: "10px" }}
                            onChange={(e) => search(e)}
                        />
                        <AButton
                            text="Sync Currency Pairs"
                            style={{ marginLeft: "15px" }}
                            onClick={() => syncPairs()}
                        />
                    </>
                }
            />
            {state === "LOADING" && <LoadingSpinner/>}
            {state === "LOADED" && (
                <table>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th colSpan={2}>Active?</th>
                        <th>Created</th>
                        <th>Base Currency</th>
                        <th>Quote Currency</th>
                    </tr>
                    </thead>
                    <tbody>
                    {getAndSortCurrencyPairs(exchange!!, searchString1, searchString2).map((pair, index) => (
                        <tr key={index}>
                            <td>{pair.id}</td>
                            <td>{pair.active ? "yes" : "no"}</td>
                            <td><CurrencyPairToggleActive pair={pair} /></td>
                            <td>{pair.created.toLocaleString(DateTime.DATETIME_SHORT)}</td>
                            <td>{pair.baseCurrency}</td>
                            <td>{pair.quoteCurrency}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load exchange."
                />
            )}
        </>
    )
}

export default Exchange

const getAndSortCurrencyPairs = (exchange: ExchangeModel, searchString1: string, searchString2: string) => {
    return exchange
        .currencyPairs
        .filter(pair => searchString1 === "" || pair.baseCurrency.toLowerCase().includes(searchString1.toLowerCase()))
        .filter(pair => searchString2 === "" || pair.quoteCurrency.toLowerCase().includes(searchString2.toLowerCase()))
        .sort((a, b) => {
            return Number(b.active) - Number(a.active)
                || a.id - b.id
        })
}