import React, {ChangeEvent, FunctionComponent} from "react"
import {DateTime} from "luxon"
import "./opportunity.css"
import AButton from "../button/button.component"

type TimeSpanProps = {
    start: DateTime
    updateStart: (start: DateTime) => void
    end: DateTime
    updateEnd: (end: DateTime) => void
    onRefresh: () => void
}

const TimeSpan: FunctionComponent<TimeSpanProps> = ({
    start,
    updateStart,
    end,
    updateEnd,
    onRefresh
}) => {
    const onChangeStart = (e: ChangeEvent<HTMLInputElement>) => {
        updateStart(DateTime.fromISO(e.target.value))
    }

    const onChangeStop = (e: ChangeEvent<HTMLInputElement>) => {
        updateEnd(DateTime.fromISO(e.target.value))
    }

    return (
        <div className="time-span">
            <AButton
                text="Refresh"
                onClick={onRefresh}
                theme="light"
            />
            <input
                type="datetime-local"
                className="datetime"
                value={formatDateTime(start)}
                onChange={(e) => { onChangeStart(e) }}
            />
            <input
                type="datetime-local"
                className="datetime"
                value={formatDateTime(end.plus({ minute: 1 }))}
                onChange={(e) => { onChangeStop(e) }}
            />
        </div>
    )
}

export default TimeSpan


const formatDateTime = (datetime: DateTime): string => {
    return datetime
        .set({ second: 0, millisecond: 0 })
        .toISO({
            format: "extended",
            includeOffset: false,
            suppressSeconds: true,
            suppressMilliseconds: true
        })
}