import React, {FunctionComponent, ReactNode} from "react"
import "./heading.css"

type HeadingProps = {
    left?: ReactNode
    right?: ReactNode
}

const Heading: FunctionComponent<HeadingProps> = ({ left, right }) => {
    return (
        <div className="heading">
            {left && (
                <div className="headingLeft">
                    <div>
                        {left}
                    </div>
                </div>
            )}
            {right && (
                <div className="headingRight">
                    <div>
                        {right}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Heading