export const HOME = "/"

export const DEBUG = "/debug/"

export const DECISION_DATA = "/decision-data/"

export const EXCHANGES = "/exchange/"

export const EXCHANGE = (identifier: string) => `/exchange/${identifier}/`
export const OPPORTUNITIES = "/opportunity/"
export const OPPORTUNITIES_INTER_EXCHANGE = `${OPPORTUNITIES}inter-exchange/`

export const OPPORTUNITIES_EXCHANGE = (identifier: string) => `/opportunity/exchange/${identifier}`
export const RATES_TO_WATCH = "/rate-to-watch/"
export const RATE_TO_WATCH = (id: number) => `/rate-to-watch/${id}/`

export const RATE_TO_WATCH_NEW = "/rate-to-watch/new"

export const TRADES = "/trades/"

export const RESTART = "/restart/"