import {CurrencyPairModel} from "./currency-pair.model"
import {mapResponseDataToExchangeModel} from "../exchange/exchange.mapper"

export const mapResponseDataToCurrencyPairModels = (data: any): CurrencyPairModel[] => {
    let pairs = []
    if (data && data.length > 0) {
        pairs = data.map((d: any) => mapResponseDataToCurrencyPairModel(d))
    }
    return pairs
}

export const mapResponseDataToCurrencyPairModel = (data: any): CurrencyPairModel => {
    return data && {
        id: data.id,
        active: data.active,
        created: data.created,
        exchange: mapResponseDataToExchangeModel(data.exchange),
        baseCurrency: data.baseCurrency,
        quoteCurrency: data.quoteCurrency
    }
}