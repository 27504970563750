import {FunctionComponent} from "react"
import {NavLink} from "react-router-dom"
import {useAuth0} from "@auth0/auth0-react";
import {DEBUG, DECISION_DATA, EXCHANGES, OPPORTUNITIES_INTER_EXCHANGE, RESTART, TRADES} from "../../routes"
import "./header.css"

const Header: FunctionComponent = () => {
    const { logout } = useAuth0()

    return (
        <header>
            <div className="container">
                <NavLink
                    to={EXCHANGES}
                    className={({ isActive }) => (isActive ? "active" : "")}
                >
                    <span>Exchanges &amp; Currencies</span>
                </NavLink>
                <NavLink
                    to={DECISION_DATA}
                    className={({ isActive }) => (isActive ? "active" : "")}
                >
                    <span>Decision Data</span>
                </NavLink>
                <NavLink
                    to={OPPORTUNITIES_INTER_EXCHANGE}
                    className={({ isActive }) => (isActive ? "active" : "")}
                >
                    <span>Opportunities</span>
                </NavLink>
                <NavLink
                    to={TRADES}
                    className={({ isActive }) => (isActive ? "active" : "")}
                >
                    <span>Trades</span>
                </NavLink>
                <NavLink
                    to={RESTART}
                    className={({ isActive }) => (isActive ? "active" : "")}
                >
                    <span>Restart</span>
                </NavLink>
                <NavLink
                    to={DEBUG}
                    className={({ isActive }) => (isActive ? "active" : "")}
                >
                    <span>DEBUG</span>
                </NavLink>
                <a
                    href="/"
                    className="logout"
                    onClick={() => logout()}
                >
                    Abmelden
                </a>
            </div>
        </header>
    )
}

export default Header