import React, {FunctionComponent} from "react"
import "./loading-spinner.css"

export const LoadingSpinner: FunctionComponent = () => {
    return (
        <div className="text-center">
            <div className="lds-ellipsis">
                <div style={{ backgroundColor: "#444" }} />
                <div style={{ backgroundColor: "#444" }} />
                <div style={{ backgroundColor: "#444" }} />
                <div style={{ backgroundColor: "#444" }} />
            </div>
        </div>
    )
}